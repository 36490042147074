import request from '@/utils/request'

export default {
  listDepart (params) {
    return request({
      url: '/depart/tree',
      method: 'get',
      params: params
    })
  },
  listAllCompany () {
    return request({
      url: '/company/listAll',
      method: 'get'
    })
  },
  getDepartById (id) {
    return request({
      url: '/depart/detail',
      method: 'get',
      params: id
    })
  },
  addDepart (data) {
    return request({
      url: '/depart/add',
      method: 'POST',
      data: data
    })
  },
  updateDepart (data) {
    return request({
      url: '/depart/modify',
      method: 'POST',
      data: data
    })
  },
  handlerDepart (data) {
    return request({
      url: '/depart/updateStatus',
      method: 'POST',
      data: data
    })
  }
}
